import React, { PureComponent } from "react"
import Layout from '../components/layout';
import TermsOfService from '../components/terms-of-service';

export default class TermsOfServicePage extends PureComponent {
  render() {
    return (
      <Layout>
        <TermsOfService/>
      </Layout>
    )
  }
}
